import { TenantId } from './tenant-id';
import { Uuid } from './uuid';
import { StringIsoDate } from './string-iso-date';
import { ToastMessageType } from './toast-message-type';

// DO NOT MODIFY THIS COMMENT autogenerated start
export type TenantEventTypeValue =
  | 'DatasourceTemplateDeleteDone'
  | 'DatasourceTemplateDeleteStarted'
  | 'DatasourceTemplateFetchDone'
  | 'DatasourceTemplateFetchFailed'
  | 'DatasourceTemplateFetchProgress'
  | 'DatasourceTemplateFetchStarted'
  | 'DatasourceTemplateImportDone'
  | 'DatasourceTemplateImportFailed'
  | 'DatasourceTemplateImportStarted'
  | 'SystemNotification'
  | 'TenantDatasourceNewAvailable'
  | 'TenantDatasourceParsedData'
  | 'TenantDatasourceReceivedData'
  | 'TenantDatasourceUpdated'
  | 'TenantFieldsNewAvailable'
  | 'TenantFieldsUpdated'
  | 'UserChanged';

export enum TenantEventType {
  DatasourceTemplateDeleteDone = 'DatasourceTemplateDeleteDone',
  DatasourceTemplateDeleteStarted = 'DatasourceTemplateDeleteStarted',
  DatasourceTemplateFetchDone = 'DatasourceTemplateFetchDone',
  DatasourceTemplateFetchFailed = 'DatasourceTemplateFetchFailed',
  DatasourceTemplateFetchProgress = 'DatasourceTemplateFetchProgress',
  DatasourceTemplateFetchStarted = 'DatasourceTemplateFetchStarted',
  DatasourceTemplateImportDone = 'DatasourceTemplateImportDone',
  DatasourceTemplateImportFailed = 'DatasourceTemplateImportFailed',
  DatasourceTemplateImportStarted = 'DatasourceTemplateImportStarted',
  SystemNotification = 'SystemNotification',
  TenantDatasourceNewAvailable = 'TenantDatasourceNewAvailable',
  TenantDatasourceParsedData = 'TenantDatasourceParsedData',
  TenantDatasourceReceivedData = 'TenantDatasourceReceivedData',
  TenantDatasourceUpdated = 'TenantDatasourceUpdated',
  TenantFieldsNewAvailable = 'TenantFieldsNewAvailable',
  TenantFieldsUpdated = 'TenantFieldsUpdated',
  UserChanged = 'UserChanged'
}
type _BasePayloadByEventType = {
  DatasourceTemplateDeleteDone: { datasourceTemplateId: unknown; dates: unknown };
  DatasourceTemplateDeleteStarted: { datasourceTemplateId: unknown; dates: unknown };
  DatasourceTemplateFetchDone: { datasourceTemplateId: unknown; dates: unknown };
  DatasourceTemplateFetchFailed: { datasourceTemplateId: unknown; dates: unknown };
  DatasourceTemplateFetchProgress: { datasourceTemplateId: unknown; dates: unknown; progress: unknown };
  DatasourceTemplateFetchStarted: { datasourceTemplateId: unknown; dates: unknown };
  DatasourceTemplateImportDone: { datasourceTemplateId: unknown; dates: unknown };
  DatasourceTemplateImportFailed: { datasourceTemplateId: unknown; dates: unknown };
  DatasourceTemplateImportStarted: { datasourceTemplateId: unknown; dates: unknown };
  SystemNotification: { message: unknown; type: unknown };
  TenantDatasourceNewAvailable: {};
  TenantDatasourceParsedData: { datasourceId: unknown; parsedUploadedFileId: unknown };
  TenantDatasourceReceivedData: { datasourceId: unknown };
  TenantDatasourceUpdated: {};
  TenantFieldsNewAvailable: {};
  TenantFieldsUpdated: {};
  UserChanged: { userId: unknown };
};
// DO NOT MODIFY THIS COMMENT autogenerated end
type BasePayloadByEventType<
  ActualObj extends _BasePayloadByEventType & {
    [key in keyof ActualObj]: key extends keyof _BasePayloadByEventType ? ActualObj[key] : never;
  }
> = ActualObj;
type PayloadByEventType = BasePayloadByEventType<{
  DatasourceTemplateFetchStarted: {
    datasourceTemplateId: Uuid;
    dates: null | StringIsoDate[];
  };
  DatasourceTemplateFetchProgress: {
    datasourceTemplateId: Uuid;
    dates: null | StringIsoDate[];
    progress: number;
  };
  DatasourceTemplateFetchFailed: {
    datasourceTemplateId: Uuid;
    dates: null | StringIsoDate[];
  };
  DatasourceTemplateFetchDone: {
    datasourceTemplateId: Uuid;
    dates: null | StringIsoDate[];
  };
  DatasourceTemplateDeleteStarted: {
    datasourceTemplateId: Uuid;
    dates: null | StringIsoDate[];
  };
  DatasourceTemplateDeleteDone: {
    datasourceTemplateId: Uuid;
    dates: null | StringIsoDate[];
  };
  DatasourceTemplateImportStarted: {
    datasourceTemplateId: Uuid;
    dates: null | StringIsoDate[];
  };
  DatasourceTemplateImportFailed: {
    datasourceTemplateId: Uuid;
    dates: null | StringIsoDate[];
  };
  DatasourceTemplateImportDone: {
    datasourceTemplateId: Uuid;
    dates: null | StringIsoDate[];
  };
  TenantFieldsNewAvailable: {};
  TenantFieldsUpdated: {};
  TenantDatasourceNewAvailable: {};
  TenantDatasourceUpdated: {};
  UserChanged: {
    userId: Uuid;
  };
  SystemNotification: {
    message: string;
    type: ToastMessageType | null | undefined;
  };
  TenantDatasourceParsedData: {
    datasourceId: Uuid;
    parsedUploadedFileId: Uuid;
  };
  TenantDatasourceReceivedData: {
    datasourceId: Uuid;
  };
}>;

type TenantEventTypeDatasourceTemplate =
  | TenantEventType.DatasourceTemplateFetchStarted
  | TenantEventType.DatasourceTemplateFetchProgress
  | TenantEventType.DatasourceTemplateFetchFailed
  | TenantEventType.DatasourceTemplateFetchDone
  | TenantEventType.DatasourceTemplateDeleteStarted
  | TenantEventType.DatasourceTemplateDeleteDone
  | TenantEventType.DatasourceTemplateImportStarted
  | TenantEventType.DatasourceTemplateImportFailed
  | TenantEventType.DatasourceTemplateImportDone;
export const TenantEventTypesDatasourceTemplate: TenantEventTypeDatasourceTemplate[] = [
  TenantEventType.DatasourceTemplateFetchStarted,
  TenantEventType.DatasourceTemplateFetchProgress,
  TenantEventType.DatasourceTemplateFetchFailed,
  TenantEventType.DatasourceTemplateFetchDone,
  TenantEventType.DatasourceTemplateDeleteStarted,
  TenantEventType.DatasourceTemplateDeleteDone,
  TenantEventType.DatasourceTemplateImportStarted,
  TenantEventType.DatasourceTemplateImportFailed,
  TenantEventType.DatasourceTemplateImportDone
];

export type TenantEventDatasourceTemplate = TenantEvent<TenantEventTypeDatasourceTemplate>;

export interface TenantEvent<T extends TenantEventTypeValue = TenantEventTypeValue> {
  tenantId: TenantId;
  type: T;
  payload: PayloadByEventType[T];
}
