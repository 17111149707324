import { Injectable } from "@angular/core";
import { ServerService } from "../http/server.service";
import { HostsService } from "../hosts.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AdvancedDateRange } from "src/app/shared/form/advanced-date-range/advanced-date-range.model";
import { DatasourceType } from "src/app/_models/datasource-type";
import {
  OptionDatasourceTemplates,
  OptionSharedDatasources,
} from "./reportings.service";
import { FieldAlias } from "src/app/shared/field-alias";
import { BaseSemanticType } from "src/app/_models/semantic-type";
import { assertTenantId, TenantId } from "src/../../src/types/tenant-id";
import { StringNotEmpty } from "src/../../src/types/string-not-empty";
import { Uuid } from "src/../../src/types/uuid";
import { validString } from "src/../../src/types/string";
import {
  getUnixTimestampSecondsNow,
  isUnixTimestampSeconds,
  UnixTimestampSeconds,
} from "src/../../src/types/unix-timestamp-seconds";
import { Email } from "src/../../src/types/email";
import {
  TenantDatasource as _TenantDatasource,
  TenantDatasourceWithoutTemplates as _TenantDatasourceWithoutTemplates,
} from "../../../../../src/types/api/tenant/tenant-datasource";
import {
  TenantDatasourceTemplate as _TenantDatasourceTemplate,
  TenantDatasourceTemplateWithoutDatasource as _TenantDatasourceTemplateWithoutDatasource,
} from "../../../../../src/types/api/tenant/tenant-datasource-template";
import {
  Tenant as _Tenant,
  TenantWithDatasourcesCount as _TenantWithDatasourcesCount,
} from "../../../../../src/types/api/tenant/tenant";
import { TenantTemplate as _TenantTemplate } from "../../../../../src/types/api/tenant/tenant-template";
import { JsonData } from "../../../../../src/types/json-data";
import { Scalar } from "../../../../../src/types/scalar";

export type Tenant<WithDatasourceCount extends boolean = false> =
  _Tenant<WithDatasourceCount>;
export type TenantWithDatasourcesCount = _TenantWithDatasourcesCount;

export enum TenantDatasourceStatus {
  NO_TEMPLATES = "datasource.status.no_templates",

  ACTIVE = "datasource.status.active",
  IGNORE_IN_EXPLORE = "datasource.status.ignore_in_explore",
  IMPORT_PAUSED = "datasource.status.import_paused",
  DEACTIVATED = "datasource.status.deactivated",

  SPREADSHEET_BUCKET_FILE_READY = "datasource.status.excel_ftp.file_ready",
  SPREADSHEET_MAIL_FILE_READY = "datasource.status.excel_mail.file_ready",
}
export type TenantDatasourceWithoutTemplates =
  _TenantDatasourceWithoutTemplates;
export type TenantDatasource<WithStatus extends boolean = false> =
  _TenantDatasource<WithStatus>;
export type TenantDatasourceWithStatus = _TenantDatasource<true>;

export interface TenantFtpUser {
  id: string;
  username: string;
  password: string;
  datasourceId: string;
}

export interface TenantDatasourceMailbox {
  id: Uuid;
  username: string;
  email: string;
  datasourceId: Uuid;
}

export type TenantTemplate = _TenantTemplate;

export interface BaseTemplate {
  name: string;
  dimensions: (
    | string
    | {
        key: string;
        params: object;
      }
  )[];
  metrics: (
    | string
    | {
        key: string;
        params: object;
      }
  )[];
  meta: JsonData & (object | null);
}

export type TenantDatasourceTemplateWithoutDatasource =
  _TenantDatasourceTemplateWithoutDatasource;
export type TenantDatasourceTemplate = _TenantDatasourceTemplate;

export type TenantCredentialsStatus = "unknown" | "valid" | "invalid";

export interface TenantCredentials {
  id: Uuid;
  tenantId: TenantId;
  datasourceTypes: string[];
  status: TenantCredentialsStatus;
  label: string;
  available: boolean;
  clientId?: string;
  mediaDeskHost?: string;
  managerAccountId?: string;
  userId?: string;
  userEmail?: string;
  userName?: string;
  globalCompanyId?: string;
}
export interface TenantCredentialsWithSecret extends TenantCredentials {
  clientSecret?: string;
}

export function isInstanceOfTenantCredentials(
  object: unknown
): object is TenantCredentials {
  return (
    typeof object === "object" &&
    object !== null &&
    object.hasOwnProperty("id") &&
    object.hasOwnProperty("tenantId") &&
    object.hasOwnProperty("datasourceTypes") &&
    object.hasOwnProperty("status") &&
    object.hasOwnProperty("label")
  );
}

export interface TenantDatasourceTypeInfoCredentialsFormField {
  key: string;
  label: string;
  description: null | string;
  format: null | string;
  exampleValue: null | string;
  isSecret?: boolean; // TODO make it required
}

export interface TenantDatasourceTypeInfo {
  type: DatasourceType;
  label: string;
  description: null | string;
  oauth: boolean;
  upload: boolean;
  credentialsForm: boolean;
  credentialsFormFields: TenantDatasourceTypeInfoCredentialsFormField[];
  show: boolean;
}

export interface TranslationsResponse {
  [language: string]: {
    [key: string]: string;
  };
}

export interface TenantMetaTemplateCustomField {
  name: string;
  formula: string;
  label: string;
  description?: string | null;
  hidden?: boolean;
  displayDecimals?: number | null;
  semanticType?: BaseSemanticType | null;
}

export interface TenantMetaTemplate {
  tenantId: TenantId;
  type: string;
  name: string;
  description: string | null;
  imageUrl: string | null;
  active: boolean;
  credentialsId: string | null;
  templates: {
    name: string;
    dimensions: (
      | string
      | {
          key: string;
          params: object;
        }
    )[];
    metrics: (
      | string
      | {
          key: string;
          params: object;
        }
    )[];
    meta: JsonData & object;
  }[];
  dimensionOnlyTemplates: {
    name: string;
    dimensions: (
      | string
      | {
          key: string;
          params: object;
        }
    )[];
    metrics: (
      | string
      | {
          key: string;
          params: object;
        }
    )[];
    meta: JsonData & object;
  }[];
  customDimensions: TenantMetaTemplateCustomField[];
  customMetrics: TenantMetaTemplateCustomField[];
  defaultHiddenFields: string[];
  defaultEnabledFields: string[];
}

export interface DynamicTemplateSimplePredefined {
  label: string;
  dimensions: string[];
  metrics: string[];
}

export interface DynamicTemplateInfo {
  credentials: {
    required: boolean;
    complete: boolean;
  };
  meta?: {
    required: boolean;
    nextFields: DynamicTemplateInfoMetaField[];
    complete: boolean;
  };
  simplePredefined?: DynamicTemplateSimplePredefined[];
  dimensions?: {
    min: number;
    max: null | number;
    required: string[];
    available: DynamicTemplateInfoReportField[];
  };
  metrics?: {
    min: number;
    max: null | number;
    required: string[];
    available: DynamicTemplateInfoReportField[];
  };
  complete: boolean;
}

export interface DynamicTemplateInfoMetaField {
  key: string;
  type: "bool" | "int" | "float" | "string";
  regex: string | null;
  options: DynamicTemplateInfoMetaFieldOption[] | null;
  nullable: boolean;
  multiple: boolean;
}

export interface DynamicTemplateInfoMetaFieldOption {
  value: null | boolean | number | string;
  label: string;
  imageUrl: string | null;
}

export interface DynamicTemplateInfoReportField {
  key: string;
  label: string | null;
  description: string | null;
  groupKey: string | null;
  groupLabel: string | null;
}

export type DynamicTemplateMetaDataValue = null | Scalar;

export interface DynamicTemplateMetaData {
  [key: string]: DynamicTemplateMetaDataValue;
}

export interface DynamicTemplate {
  datasourceType: string;
  credentialsId: string | null;
  meta: DynamicTemplateMetaData;
  dimensions: string[];
  metrics: string[];
}

export type ReportExportFormatType = "xlsx" | "csv";
export type ReportTargetTypeType = "email";

export interface Report {
  id: Uuid;
  tenantId: TenantId;
  ownerId: Uuid;
  name: string;
  active: boolean;
  activeSince: string; // "2022-08-16"
  activeUntil: string | null; // "2022-08-16"
  rrule: string;
  prevOccurences: string[]; // "2022-08-16"
  nextOccurences: string[]; // "2022-08-16"
  targets: {
    type: ReportTargetTypeType;
    receiverEmail: string;
  }[];
  exportFormat: ReportExportFormatType;
  exportRange: string; // "2022-08-16:t+0d"
  exportFields: Uuid[];
  exportFilter: Uuid | null;
}

export interface ReportWithLogs extends Report {
  logs: ReportLog[];
  exportRangeObj: AdvancedDateRange;
  exportRangeStartLabel: Promise<string>;
  exportRangeEndLabel: Promise<string>;
}

export interface ReportLog {
  id: string;
  reportId: string;
  scheduledFor: string; // "2022-05-04T02:00:00+0200"
  createdAt: number;
  doneAt: number | null;
  errorId: string | null;
  errorMessage: string | null;
  errorDescription: string | null;
  done: boolean;
  success: boolean | null;
}

export interface ReportLogWithStatus extends ReportLog {
  status: {
    icon: string;
    title: string;
    color: string;
  };
}

export interface AuditLog {
  id: string;
  timestamp: number;
  action: string;
  tenantId: TenantId | null;
  objType: string;
  objId: string | null;
  userType: string;
  userId: string | null;
  userInfo: string | any | null;
  payload: string | any | null;
}

export interface SharedDatasource {
  id: Uuid;
  sourceTenantId: TenantId;
  sourceTenantName: string;
  targetTenantIds: TenantId[];
  datasourceTemplateIds: Uuid[];
  datasources: {
    id: Uuid;
    name: string;
  }[];
  nativeFieldIds: Uuid[];
  filterField: Uuid | null;
}

export interface ExplorePreset {
  id: Uuid;
  tenantId: TenantId;
  ownerId: Uuid;
  createdAt: number;
  lastUsedAt: number;
  favoriteLabel: string | null;
  dateRange: string;
  fields: (Uuid | StringNotEmpty)[];
  datasourceTemplates: OptionDatasourceTemplates | string[];
  sharedDatasourceTemplates: OptionSharedDatasources | string[];
  filterFormula: string | null;
}

export interface ExplorePresetFavorite extends ExplorePreset {
  favoriteLabel: string;
}

export interface TenantStorageObject {
  filename: string;
  size: number;
  timestampUploaded: number;
}

export interface TenantDatasourceDeveloperInfo {
  type: DatasourceType;
  label: string;
  credentialsForm: null | string[];
  consoleOrganizationUrl: null | string;
  consoleClientUrl: null | string;
  consoleIamUrl: null | string;
  documentationUrls: string[];
  currentApiVersion: string | null;
  supportedApiVersionUrl: null | string;
  oauth: boolean;
  oauthSupportsState: null | boolean;
  oauthSupportsMultipleRedirectUrls: null | boolean;
  oauthRequiresVerification: null | boolean;
  oauthClients: {
    clientId: string;
    clientSecret: string;
    [key: string]: string;
  }[];
}

@Injectable({
  providedIn: "root",
})
export class TenantService {
  private readonly host: string;

  constructor(
    private _server: ServerService,
    private _hostsService: HostsService
  ) {
    this.host = this._hostsService.tenantHost;
  }

  public getAllTenants(
    withInactive: boolean
  ): Observable<TenantWithDatasourcesCount[]> {
    return this._server.request(
      "GET",
      this.host + "/tenants?filter=" + (withInactive ? "all" : "active")
    );
  }

  public getMyTenants(
    withInactive: boolean
  ): Observable<TenantWithDatasourcesCount[]> {
    return this._server.request(
      "GET",
      this.host + "/tenants/my?filter=" + (withInactive ? "all" : "active")
    );
  }

  public getTenant(tenantId: TenantId): Observable<TenantWithDatasourcesCount> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantWithDatasourcesCount>(
      "GET",
      this.host + "/tenants/" + tenantId,
      tenantId
    );
  }

  public getTranslations(tenantId: TenantId): Observable<TranslationsResponse> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TranslationsResponse>(
      "GET",
      this.host + "/translations",
      tenantId
    );
  }

  public updateTenant(
    tenantId: TenantId,
    data: {
      name?: string;
      active?: boolean;
      defaultTimeZone?: string;
      defaultCurrency?: string;
      defaultLanguage?: string;
      brandColor?: string;
      brandLogo?: string | null;
    }
  ): Observable<TenantWithDatasourcesCount> {
    return this._server.request<TenantWithDatasourcesCount>(
      "POST",
      this.host + "/tenants/" + tenantId,
      data
    );
  }

  public createTenant(data: {
    subscriptionId: Uuid;
    name: string;
    active: boolean;
  }): Observable<TenantWithDatasourcesCount> {
    return this._server.request("POST", this.host + "/tenants", data);
  }

  public activateTenant(
    tenantId: TenantId
  ): Observable<TenantWithDatasourcesCount> {
    return this._server.request(
      "POST",
      this.host + "/tenants/" + tenantId + "/activate"
    );
  }

  public deactivateTenant(
    tenantId: TenantId
  ): Observable<TenantWithDatasourcesCount> {
    return this._server.request(
      "POST",
      this.host + "/tenants/" + tenantId + "/deactivate"
    );
  }

  public removeTenant(tenantId: TenantId): Observable<void> {
    return this._server.request("DELETE", this.host + "/tenants/" + tenantId);
  }

  public getDatasources<WithStatus extends boolean>(
    tenantId: TenantId | null,
    withStatus: WithStatus
  ): Observable<TenantDatasource<WithStatus>[]> {
    return this._server.requestWithTenantId<TenantDatasource<WithStatus>[]>(
      "GET",
      this.host +
        "/datasources" +
        "?with_status=" +
        (withStatus ? "true" : "false"),
      tenantId
    );
  }

  public getDatasourcesSharedForTenant(
    tenantId: TenantId
  ): Observable<TenantDatasourceWithStatus[]> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantDatasourceWithStatus[]>(
      "GET",
      this.host + "/datasources/shared" + "?with_status=true",
      tenantId
    );
  }

  public getDatasource<WithStatus extends boolean>(
    tenantId: TenantId,
    datasourceId: Uuid,
    withStatus: WithStatus
  ): Observable<TenantDatasource<WithStatus>> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantDatasource<WithStatus>>(
      "GET",
      this.host +
        "/datasources/" +
        datasourceId +
        "?with_status=" +
        (withStatus ? "true" : "false"),
      tenantId
    );
  }

  public createDatasource<WithStatus extends boolean>(
    tenantId: TenantId,
    data: {
      name: string;
      metaTemplateName?: string;
      credentialsId?: string | null;
      type: string;
      active: boolean;
      dataSince?: string;
      dataUntil?: string | null;
      templates?: BaseTemplate[];
      dimensionOnlyTemplates?: BaseTemplate[];
      customDimensions?: TenantMetaTemplateCustomField[];
      customMetrics?: TenantMetaTemplateCustomField[];
      defaultHiddenFields?: string[];
      defaultEnabledFields?: string[];
    },
    withStatus: WithStatus
  ): Observable<TenantDatasource<WithStatus>> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantDatasource<WithStatus>>(
      "POST",
      this.host +
        "/datasources" +
        "?with_status=" +
        (withStatus ? "true" : "false"),
      tenantId,
      data
    );
  }

  public updateDatasource<WithStatus extends boolean>(
    tenantId: TenantId,
    datasourceId: Uuid,
    data: {
      name?: string;
      credentialsId?: Uuid | null;
      active?: boolean;
      dataSince?: string;
      dataUntil?: string | null;
    },
    withStatus: WithStatus
  ): Observable<TenantDatasource<WithStatus>> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantDatasource<WithStatus>>(
      "POST",
      this.host +
        "/datasources/" +
        datasourceId +
        "?with_status=" +
        (withStatus ? "true" : "false"),
      tenantId,
      data
    );
  }

  public setDatasourceManualRetry(
    tenantId: TenantId,
    datasourceId: Uuid
  ): Observable<void> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<void>(
      "POST",
      this.host + "/datasources/" + datasourceId + "/manual-retry",
      tenantId
    );
  }

  public setManyDatasourcesManualRetry(
    tenantId: TenantId,
    datasourceIds: Uuid[]
  ): Observable<void> {
    return this._server.requestWithTenantId<void>(
      "POST",
      this.host + "/datasources/manual-retry",
      tenantId,
      datasourceIds
    );
  }

  public addDatasourceTemplates(
    tenantId: TenantId,
    data: {
      datasourceId: Uuid;
      template: {
        name: string;
        dimensions: string[];
        metrics: string[];
        meta: object;
      };
      dimensionOnly: boolean;
      active: boolean;
    }
  ): Observable<TenantDatasourceTemplate> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantDatasourceTemplate>(
      "POST",
      this.host + "/datasource_templates",
      tenantId,
      data
    );
  }

  public updateDatasourceTemplate(
    tenantId: TenantId,
    datasourceTemplateId: Uuid,
    newTemplateId: Uuid
  ): Observable<TenantDatasourceTemplate> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantDatasourceTemplate>(
      "POST",
      this.host + "/datasource_templates/" + datasourceTemplateId,
      tenantId,
      {
        templateId: newTemplateId,
      }
    );
  }

  public getCredentials(tenantId: TenantId): Observable<TenantCredentials[]> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantCredentials[]>(
      "GET",
      this.host + "/credentials",
      tenantId
    );
  }

  public createCredentials(
    tenantId: TenantId,
    data: object
  ): Observable<TenantCredentials> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantCredentials>(
      "POST",
      this.host + "/credentials",
      tenantId,
      data
    );
  }

  public createCredentialsFromAuthCode(
    tenantId: TenantId,
    datasourceType: string,
    code: string
  ): Observable<TenantCredentials> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantCredentials>(
      "POST",
      this.host +
        "/oauth/code/" +
        datasourceType +
        "?code=" +
        encodeURIComponent(code),
      tenantId
    );
  }

  public getCredentialsSecrets(
    tenantId: TenantId,
    credentialsId: Uuid
  ): Observable<TenantCredentialsWithSecret> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantCredentialsWithSecret>(
      "GET",
      this.host + "/credentials/get-secret/" + credentialsId,
      tenantId
    );
  }

  public deleteCredentials(
    tenantId: TenantId,
    credentialsId: Uuid
  ): Observable<void> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<void>(
      "DELETE",
      this.host + "/credentials/" + credentialsId,
      tenantId
    );
  }

  public deleteTemplate(
    tenantId: TenantId,
    templateId: Uuid
  ): Observable<void> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<void>(
      "DELETE",
      this.host + "/templates/" + templateId,
      tenantId
    );
  }

  public getOauthLoginUrl(
    tenantId: TenantId,
    datasourceType: string
  ): Observable<string> {
    assertTenantId(tenantId);
    return this._server
      .requestWithTenantId<{ login_url: string }>(
        "POST",
        this.host + "/oauth/login/" + datasourceType,
        tenantId
      )
      .pipe(
        map((response) => {
          return validString(response.login_url);
        })
      );
  }

  public getDatasourceMetaTemplates(
    tenantId: TenantId,
    datasourceType: string,
    credentialsId?: Uuid | null
  ): Observable<{ metaTemplates: TenantMetaTemplate[] | null }> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<{
      metaTemplates: TenantMetaTemplate[] | null;
    }>("POST", this.host + "/datasources/meta_templates", tenantId, {
      type: datasourceType,
      credentialsId: credentialsId,
    });
  }

  public getDatasourceTypes(
    tenantId: TenantId
  ): Observable<TenantDatasourceTypeInfo[]> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantDatasourceTypeInfo[]>(
      "GET",
      this.host + "/datasource_types",
      tenantId
    );
  }

  public getDatasourceDeveloperInfos(): Observable<
    TenantDatasourceDeveloperInfo[]
  > {
    return this._server.request<TenantDatasourceDeveloperInfo[]>(
      "GET",
      this.host + "/datasource_dev"
    );
  }

  public activateDatasource<WithStatus extends boolean>(
    tenantId: TenantId,
    id: Uuid,
    withStatus: WithStatus
  ): Observable<TenantDatasource<WithStatus>> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantDatasource<WithStatus>>(
      "POST",
      this.host +
        "/datasources/" +
        id +
        "/activate" +
        "?with_status=" +
        (withStatus ? "true" : "false"),
      tenantId
    );
  }

  public activateManyDatasources<WithStatus extends boolean>(
    tenantId: TenantId,
    ids: Uuid[],
    withStatus: WithStatus
  ): Observable<TenantDatasource<WithStatus>[]> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantDatasource<WithStatus>[]>(
      "POST",
      this.host +
        "/datasources/activate" +
        "?with_status=" +
        (withStatus ? "true" : "false"),
      tenantId,
      ids
    );
  }

  public deactivateDatasource<WithStatus extends boolean>(
    tenantId: TenantId,
    id: Uuid,
    withStatus: WithStatus
  ): Observable<TenantDatasource<WithStatus>> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantDatasource<WithStatus>>(
      "POST",
      this.host +
        "/datasources/" +
        id +
        "/deactivate" +
        "?with_status=" +
        (withStatus ? "true" : "false"),
      tenantId
    );
  }

  public deleteDatasource(tenantId: TenantId, id: Uuid): Observable<void> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<void>(
      "DELETE",
      this.host + "/datasources/" + id,
      tenantId
    );
  }

  public deactivateManyDatasources<WithStatus extends boolean>(
    tenantId: TenantId,
    ids: Uuid[],
    withStatus: WithStatus
  ): Observable<TenantDatasource<WithStatus>[]> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantDatasource<WithStatus>[]>(
      "POST",
      this.host +
        "/datasources/deactivate" +
        "?with_status=" +
        (withStatus ? "true" : "false"),
      tenantId,
      ids
    );
  }

  public deleteManyDatasources(
    tenantId: TenantId,
    ids: Uuid[]
  ): Observable<void> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<void>(
      "DELETE",
      this.host + "/datasources",
      tenantId,
      ids
    );
  }

  public getDatasourceFtpUser(
    tenantId: TenantId,
    datasourceId: Uuid
  ): Observable<TenantFtpUser> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantFtpUser>(
      "GET",
      this.host + "/datasources/" + datasourceId + "/ftp",
      tenantId
    );
  }

  public getDatasourceMailbox(
    tenantId: TenantId,
    datasourceId: Uuid
  ): Observable<TenantDatasourceMailbox> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantDatasourceMailbox>(
      "GET",
      this.host + "/datasources/" + datasourceId + "/mail",
      tenantId
    );
  }

  public regenerateDatasourceFtpUserPassword(
    tenantId: TenantId,
    datasourceId: Uuid
  ): Observable<TenantFtpUser> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantFtpUser>(
      "POST",
      this.host + "/datasources/" + datasourceId + "/ftp/regenerate-password",
      tenantId
    );
  }

  public regenerateDatasourceMailboxEmail(
    tenantId: TenantId,
    datasourceId: Uuid
  ): Observable<TenantDatasourceMailbox> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantDatasourceMailbox>(
      "POST",
      this.host + "/datasources/" + datasourceId + "/mail/regenerate-email",
      tenantId
    );
  }

  public getReports(tenantId: TenantId): Observable<Report[]> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<Report[]>(
      "GET",
      this.host + "/reports",
      tenantId
    );
  }

  public deleteReport(tenantId: TenantId, id: string): Observable<Report> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<Report>(
      "DELETE",
      this.host + "/reports/" + id,
      tenantId
    );
  }

  public createTemplate(
    tenantId: TenantId,
    data: {
      type: DatasourceType | string;
      name: StringNotEmpty;
      dimensions: StringNotEmpty[];
      metrics: StringNotEmpty[];
      meta: {};
    }
  ): Observable<TenantTemplate> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<TenantTemplate>(
      "POST",
      this.host + "/templates",
      tenantId,
      data
    );
  }

  public createReport(
    tenantId: TenantId,
    report: {
      name: string;
      active: boolean;
      activeSince: string; // "2022-08-16"
      activeUntil: string | null; // "2022-08-16"
      rrule: string;
      targets: string[];
      exportFormat: string;
      exportRange: string;
      exportFields: string[];
      exportFilter: string | null;
    }
  ): Observable<Report> {
    return this._server.requestWithTenantId<Report>(
      "POST",
      this.host + "/reports",
      tenantId,
      report
    );
  }

  public updateReport(
    tenantId: TenantId,
    reportId: Uuid,
    report: {
      name?: string;
      active?: boolean;
      activeSince?: string; // "2022-08-16"
      activeUntil?: string | null; // "2022-08-16"
      rrule?: string;
      targets?: string[];
      exportFormat?: string;
      exportRange?: string;
      exportFields?: string[];
      exportFilter?: string | null;
    }
  ): Observable<Report> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<Report>(
      "POST",
      this.host + "/reports/" + reportId,
      tenantId,
      report
    );
  }

  public changeReportStatus(
    tenantId: TenantId,
    id: Uuid,
    status: "activate" | "deactivate"
  ): Observable<Report> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<Report>(
      "POST",
      this.host + "/reports/" + id + "/" + status,
      tenantId
    );
  }

  public reportsLogs(
    tenantId: TenantId,
    since?: UnixTimestampSeconds
  ): Observable<ReportLog[]> {
    assertTenantId(tenantId);
    since = isUnixTimestampSeconds(since)
      ? since
      : getUnixTimestampSecondsNow(-1 * 60 * 60 * 24 * 7 * 4 * 10);
    return this._server.requestWithTenantId<ReportLog[]>(
      "GET",
      this.host + "/reports/logs?since=" + since,
      tenantId
    );
  }

  public getDynamicTemplateInfo(
    tenantId: TenantId,
    datasourceType: string,
    credentialsId: Uuid | null,
    meta: { [key: string]: null | boolean | number | string } | null,
    dimensions: string[] | null,
    metrics: string[] | null
  ): Observable<DynamicTemplateInfo> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<DynamicTemplateInfo>(
      "POST",
      this.host + "/dynamic_template/" + datasourceType,
      tenantId,
      {
        credentialsId: credentialsId ? credentialsId : undefined, // TODO support null in BE
        meta: meta,
        dimensions: dimensions,
        metrics: metrics,
      }
    );
  }

  public getAuditLogsForItem(
    itemId: Uuid,
    ignoreShow: boolean
  ): Observable<AuditLog[]> {
    return this._server.request(
      "GET",
      this.host +
        "/audit/item/" +
        itemId +
        "?ignore_show=" +
        (ignoreShow ? "true" : "false")
    );
  }

  public getAuditLogsForUser(
    userIdOrEmail: Uuid | Email,
    ignoreShow: boolean
  ): Observable<AuditLog[]> {
    return this._server.request(
      "GET",
      this.host +
        "/audit/user/" +
        userIdOrEmail +
        "?ignore_show=" +
        (ignoreShow ? "true" : "false")
    );
  }

  public getSharedDatasources(
    tenantId: TenantId
  ): Observable<SharedDatasource[]> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<SharedDatasource[]>(
      "GET",
      this.host + "/shared_datasources",
      tenantId
    );
  }

  public getMySharedDatasources(): Observable<SharedDatasource[]> {
    return this._server.request<SharedDatasource[]>(
      "GET",
      this.host + "/shared_datasources/my"
    );
  }

  public createSharedDatasource(data: {
    sourceTenantId: TenantId;
    targetTenantIds: string[];
    datasourceTemplateIds: string[];
    filterField?: Uuid | null;
  }): Observable<SharedDatasource> {
    assertTenantId(data.sourceTenantId);
    return this._server.request<SharedDatasource>(
      "POST",
      this.host + "/shared_datasources",
      data
    );
  }

  public deleteSharedDatasource(
    tenantId: TenantId,
    sharedDatasourceId: Uuid
  ): Observable<SharedDatasource> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<SharedDatasource>(
      "DELETE",
      this.host + "/shared_datasources/" + sharedDatasourceId,
      tenantId
    );
  }

  public listRelevantExplorePresets(
    tenantId: TenantId
  ): Observable<ExplorePreset[]> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<ExplorePreset[]>(
      "GET",
      this.host + "/explore_presets/my",
      tenantId
    );
  }

  public createExplorePreset(
    tenantId: TenantId,
    data: {
      favoriteLabel: string | null;
      dateRange: AdvancedDateRange;
      fields: (FieldAlias | string)[];
      datasourceTemplates: OptionDatasourceTemplates | string[];
      sharedDatasourceTemplates: OptionSharedDatasources | string[];
      filterFormula: string | null;
    }
  ): Observable<ExplorePreset> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<ExplorePreset>(
      "POST",
      this.host + "/explore_presets",
      tenantId,
      {
        favoriteLabel: data.favoriteLabel,
        dateRange: data.dateRange.serialized,
        fields: data.fields.map((f) =>
          f instanceof FieldAlias ? f.toString() : f
        ),
        datasourceTemplates: data.datasourceTemplates,
        sharedDatasourceTemplates: data.sharedDatasourceTemplates,
        filterFormula: data.filterFormula,
      }
    );
  }

  public deleteExplorePreset(
    tenantId: TenantId,
    explorePresetId: Uuid
  ): Observable<ExplorePreset> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<ExplorePreset>(
      "DELETE",
      this.host + "/explore_presets/" + explorePresetId,
      tenantId
    );
  }

  public notifyUseExplorePreset(
    tenantId: TenantId,
    explorePresetId: Uuid
  ): Observable<ExplorePreset> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<ExplorePreset>(
      "POST",
      this.host + "/explore_presets/" + explorePresetId + "/notify_use",
      tenantId
    );
  }

  public updateExplorePreset(
    tenantId: TenantId,
    explorePresetId: Uuid,
    data: {
      favoriteLabel: string | null;
    }
  ): Observable<ExplorePreset> {
    assertTenantId(tenantId);
    return this._server.requestWithTenantId<ExplorePreset>(
      "POST",
      this.host + "/explore_presets/" + explorePresetId,
      tenantId,
      data
    );
  }
}
