export interface PublicError {
  readonly message: string;
  readonly publicMessage: string;
  readonly publicDescription: string | null;
  readonly severity: 'danger' | 'warning' | 'info' | 'success';
}

export function isPublicError(err: unknown): err is PublicError {
  return (
    typeof err === 'object' &&
    err !== null &&
    typeof (err as PublicError).message === 'string' &&
    typeof (err as PublicError).publicMessage === 'string' &&
    ((err as PublicError).publicDescription === null || typeof (err as PublicError).publicDescription === 'string') &&
    typeof (err as PublicError).severity === 'string'
  );
}

export function createPublicError(
  message: string,
  data: {
    publicMessage: string;
    publicDescription?: string | null;
    severity?: 'danger' | 'warning' | 'info' | 'success';
  }
): PublicError & Error {
  const err: Error & {
    message: string;
    publicMessage: string;
    publicDescription: string | null;
    severity: 'danger' | 'warning' | 'info' | 'success';
  } = new Error(message) as Error & {
    message: string;
    publicMessage: string;
    publicDescription: string | null;
    severity: 'danger' | 'warning' | 'info' | 'success';
  };
  err.severity = data.severity ?? 'danger';
  err.publicMessage = data.publicMessage;
  err.publicDescription = data.publicDescription ?? null;
  return err;
}
