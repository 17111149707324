import { Injectable } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { HostsService } from "./hosts.service";
import { CurrentTenantService } from "./current-tenant.service";
import { WebSocketWithRetry } from "src/app/shared/websocket-with-retry";
import { filter, map, switchMap } from "rxjs/operators";
import { Scopes } from "src/app/_models/scope";
import { AuthUserReadonlyRepositoryService } from "./repository/auth-user-readonly-repository.service";
import { TenantId } from "src/../../src/types/tenant-id";
import {
  TenantEvent,
  TenantEventTypeValue,
  TenantEventTypesDatasourceTemplate,
  TenantEventDatasourceTemplate,
} from "../../../../src/types/tenant-event";

@Injectable({
  providedIn: "root",
})
export class TenantEventsService {
  private _events: Observable<TenantEvent>;

  constructor(
    private _currentTenantService: CurrentTenantService,
    private _authUserRepositoryService: AuthUserReadonlyRepositoryService,
    private _hostsService: HostsService
  ) {
    this._events = this._createWs();
  }

  private getWsUrl(): Observable<string | null> {
    return this._authUserRepositoryService.observableAuthUser.pipe(
      map((user): string | null => {
        if (!user || !user.iqUser.userCanForAnyTenant(Scopes.EVENTS_LISTEN)) {
          return null;
        }

        return `${
          this._hostsService.eventsHost
        }/tenants/my?token=${encodeURIComponent(user.accessToken.toString())}`;
      })
    );
  }

  public events(): Observable<TenantEvent> {
    return this._currentTenantService.observable.pipe(
      switchMap((tenant) => {
        return !tenant ? EMPTY : this.eventsByTenantId(tenant.id);
      })
    );
  }

  public eventsFor<T extends TenantEventTypeValue>(
    type: T | T[]
  ): Observable<TenantEvent<T>> {
    return this._currentTenantService.observable
      .pipe(
        switchMap((tenant) => {
          return !tenant ? EMPTY : this.eventsByTenantId(tenant.id);
        })
      )
      .pipe(
        filter(
          (event: TenantEvent<TenantEventTypeValue>): event is TenantEvent<T> =>
            Array.isArray(type)
              ? (type as TenantEventTypeValue[]).includes(event.type)
              : event.type === type
        )
      );
  }

  public eventsForDatasourceTemplate(): Observable<TenantEventDatasourceTemplate> {
    return this.eventsFor(TenantEventTypesDatasourceTemplate);
  }

  public eventsByTenantId(tenantId: TenantId): Observable<TenantEvent> {
    return this._events.pipe(filter((event) => event.tenantId === tenantId));
  }

  private _createWs(): Observable<TenantEvent> {
    return new WebSocketWithRetry(this.getWsUrl()).messages.pipe(
      map((message: string) => {
        const data: TenantEvent = JSON.parse(message);
        if (
          typeof data !== "object" ||
          typeof data.type !== "string" ||
          typeof data.payload !== "object"
        ) {
          return null;
        } else {
          // @ts-ignore
          data.type = data.type.replace(/^DataSource/, "Datasource");
          return data;
        }
      }),
      filter((data: TenantEvent | null): data is TenantEvent => data !== null)
    );
  }
}
